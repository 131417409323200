'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    _ = require('lodash');


/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();
        }
    });
};

var colorSwatchesHover = function ($this) {
    var $pdpMain = $('#pdpMain'),
        largeImg = $this.data('lgimg'),
        $imgZoom = $pdpMain.find('.main-image.slick-current .zoom-img'),
        $mainImage = $pdpMain.find('.slick-current .primary-image');

    if (!largeImg) { return; }
    // store the old data from main image for mouseleave handler
    $this.data('lgimg', {
        hires: $imgZoom.data('href'),
        url: $mainImage.attr('src'),
        alt: $mainImage.attr('alt'),
        title: $mainImage.attr('title')
    });
    // set the main image
    image.setMainImage(largeImg);
};

module.exports = function () {
    var $pdpMain = $('#pdpMain');
    // hover on swatch - should update main image with swatch image
    $pdpMain.on('mouseenter mouseleave', '.swatches.color .swatchanchor', function() {
        var timer;
        clearTimeout(timer);
        timer = setTimeout(colorSwatchesHover($(this)), 100);
    });

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        updateContent(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

    var quantityCtas = ['button.js-quantity-decrease-button', 'button.js-quantity-increase-button'];
    $(quantityCtas).each(function(idx, attr) {
        $pdpMain.on('click', attr, function() {
            var $quantityInput = $(attr).siblings('.js-product-quantity-input');
            var quantityInputVal = parseInt($quantityInput.val());
            var modificatedQuantity;

            if ($(attr).hasClass('js-quantity-decrease-button')) {
                modificatedQuantity = quantityInputVal > 1 ? quantityInputVal - 1 : 1;
            } else if ($(attr).hasClass('js-quantity-increase-button')) {
                modificatedQuantity = quantityInputVal + 1;
            }

            return $quantityInput.val(modificatedQuantity);
        });
    });
};
