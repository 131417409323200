'use strict';
var dialog = require('../../dialog');
var util = require('../../util');
var carouselConfig = require('../../configs/carousel');
var qs = require('qs');
var url = require('url');
var _ = require('lodash');

var zoomMediaQuery = matchMedia('(min-width: 960px)');


/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description Enables the zoom viewer on the product detail page
 * @param zmq {Media Query List}
 */
function loadZoom (zmq) {
    var $imgZoom = $('#pdpMain .main-image .zoom-img'),
        hiresUrl;
    if (!zmq) {
        zmq = zoomMediaQuery;
    }
    if ($imgZoom.length === 0 || dialog.isActive() || util.isMobile() || !zoomMediaQuery.matches) {
        // remove zoom
        $imgZoom.trigger('zoom.destroy');
        return;
    }

    $imgZoom.each(function(ind, item) {
        hiresUrl = $(item).data('href');

        if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1 && zoomMediaQuery.matches) {
            $(item).trigger('zoom.destroy');
            $(item).zoom({
                url: hiresUrl
            });
        }
    });
}

zoomMediaQuery.addListener(loadZoom);

/**
 * Sliders initialization handler. Monitors the loading of images inside the slider container
 * @param {jQuery object} $sliderContainer - slider container jQuery object
 * @param {JSON} settings - json with slider settings
 */
function initSliderHandler($sliderContainer, settings) {
    var $images = $sliderContainer.find('.js-slider-image').filter((ind, image) => {
        return image.complete === false;
    });
    var i = 0;    
    if (!$images.length) {
        $sliderContainer.slick(settings);
    } else {
        $images.on('load', function() {
            i++;
            if(i === $images.length) {
                setTimeout(function(){
                    $sliderContainer.slick(settings).data('slick',settings);
                },0)
            }
        });
    }
}

/**
 * @description initialize mobile slider for images
 */
function initProductSlider() {     
    initSliderHandler(
        $('.pdp-primary-slider'),
        carouselConfig.pdpPrimaryCarousel
    );
    
    initSliderHandler(
        $('.pdp-thumbnails-slider'),
        carouselConfig.pdpThumbCarousel
    );
}

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
function setMainImage (attr) {
    var atts =  typeof attr === 'string' ? JSON.parse(attr) : attr;

    $('#pdpMain .main-image.slick-current .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });
    updatePinButton(atts.url);
    if (!dialog.isActive() && !util.isMobile() && $('#pdpMain .main-image').length < 2) {
        $('#pdpMain .main-image.slick-current .primary-image').attr('href', atts.hires);
    }
}

function updatePinButton (imageUrl) {
    var pinButton = document.querySelector('.share-icon[data-share=pinterest]');
    if (!pinButton) {
        return;
    }
    var newUrl = imageUrl;
    if (!imageUrl) {
        newUrl = document.querySelector('#pdpMain .primary-image').getAttribute('src');
    }
    var href = url.parse(pinButton.href);
    var query = qs.parse(href.query);
    query.media = url.resolve(window.location.href, newUrl);
    query.url = window.location.href;
    var newHref = url.format(_.extend({}, href, {
        query: query, // query is only used if search is absent
        search: qs.stringify(query)
    }));
    pinButton.href = newHref;
}

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-primary-image-carousel');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    loadZoom();
    initProductSlider();
}

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    if (dialog.isActive() || util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }
    updatePinButton();
    loadZoom();

    initProductSlider();

    $('#pdpMain').on('click', '.main-image', function() { 
        return false; 
    });
};
module.exports.loadZoom = loadZoom;
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.initProductSlider = initProductSlider;
module.exports.initSliderHandler = initSliderHandler;
