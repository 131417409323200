'use strict';

var settings = {
    homePageSlider: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        arrows: false
    },
    pdpPrimaryCarousel: {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: '.pdp-thumbnails-slider',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true
                }
            }
        ]
    },
    pdpThumbCarousel: {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        asNavFor: '.pdp-primary-slider',
        centerMode: false,
        centerPadding: '0px',
        focusOnSelect: true
    },
    recommendationCarousel: {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: true,
            slidesToShow: 2,
            dots: false
          }
        }
        ]
    },
    einsteinRecommendationCarousel: {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: true,
            slidesToShow: 2,
            dots: false
          }
        }
        ]
    }
};

module.exports = settings;
